@tailwind base;
@tailwind components;
@tailwind utilities;

/* ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: #dcdcdc; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #b0c3cb; 
  } */

/* @keyframes slide {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  } */
.new_footer_top {
  padding: 120px 0px 270px;
  position: relative;
  overflow-x: hidden;
}

.new_footer_top .company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #6a7695;
  margin-bottom: 20px;
}

.new_footer_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}

.relative:hover .absolute {
  display: block;
}

#mobile-menu-2 {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

/* globals.css */
.new_footer_top .f_widget.about-widget .f_list li {
  margin-bottom: 11px;
}

.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 24px;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1a718c;
  /* Green color */
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top:hover {
  background-color: #c7a314;
}

.scroll-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

.scroll-progress-bar div {
  height: 3px;
  background-color: #007bff;
}
.py-24{
  padding-bottom: 24px;
  padding-top: 24px;
}
.new_footer_top {
padding-top: 0px;
}

.new_footer_top .footer_bg {
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  bottom: 0;
  background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEigB8iI5tb8WSVBuVUGc9UjjB8O0708X7Fdic_4O1LT4CmLHoiwhanLXiRhe82yw0R7LgACQ2IhZaTY0hhmGi0gYp_Ynb49CVzfmXtYHUVKgXXpWvJ_oYT8cB4vzsnJLe3iCwuzj-w6PeYq_JaHmy_CoGoa6nw0FBo-2xLdOPvsLTh_fmYH2xhkaZ-OGQ/s16000/footer_bg.png") no-repeat scroll center 0;
  width: 100%;
  height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
  background: url("../src/assets/img/dharmesh73-removebg-preview.png") no-repeat center center;
  width: 330px;
  height: 100px;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 30%;
  -webkit-animation: myfirst 22s linear infinite;
  animation: myfirst 35s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
  background: url("../src/assets/img/hetu6666-removebg-preview.png") no-repeat center center;
  width: 330px;
  height: 100px;
  background-size: 100%;
  padding-bottom: 465px;
  left: 30%;
  position: absolute;
  -webkit-animation: myfirst 30s linear infinite;
  animation: myfirst 40s linear infinite;
}

@-moz-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}
.phone-input .PhoneInputInput {
  outline: none;
  box-shadow: none;
}
